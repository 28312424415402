<template>
  <div class="kmCenter">
    <div class="handBox">
      <el-button type="primary" @click="goBack" style="width: 92px">返回</el-button>
      <span class="pageTopic">到件产品管理</span>
    </div>
    <div class="searchBar">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-width="80px"
      >
        <el-form-item label="宝贝姓名" label-width="80px">
          <el-input
            v-model="formInline.name"
            placeholder="请输入"
            @clear="search"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model="formInline.phone"
            placeholder="请输入"
            @clear="search"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="选片时间" label-width="80px">
          <el-date-picker
            v-model="formInline.chooseDate"
            type="date"
            placeholder="选择日期"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            @change="clearPickDate"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="确认时间" label-width="80px">
          <el-date-picker
            v-model="formInline.confirmDate"
            type="date"
            placeholder="选择日期"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            @change="clearPickDate"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知取件时间" label-width="120px">
          <el-date-picker
            v-model="formInline.pickUpDate"
            type="date"
            placeholder="选择日期"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            @change="clearPickDate"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input
            v-model="formInline.keyWord"
            @input="search"
            placeholder="姓名/手机号/备注 模糊搜索"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="search"
            :loading="tableLoading"
            style="width: 92px; margin-left: 20px"
            >查询</el-button
          >
          <el-button
            type="success"
            @click="openAdd"
            style="width: 92px; margin-left: 20px"
            >导入</el-button
          >
          <!-- <el-button
            type="warning"
            @click="exportExcel"
            style="width: 102px; margin-left: 20px"
            >导出Excel</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="mainList">
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 10px"
        stripe
        border
        size="medium"
      >
        <el-table-column align="center" label="序号" fixed width="60">
          <template slot-scope="scope">
            <div>{{ scope.$index + (pages.page - 1) * pages.pageNum + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="parentName"
          label="家长姓名"
          fixed
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          fixed
          label="宝宝姓名"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="手机号"
          width="180"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="orderPrice"
          label="套系"
          width="180"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="chooseDate"
          label="选片时间"
          width="130"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="confirmDate"
          label="确认时间"
          width="130"
        ></el-table-column>
        <el-table-column align="center" width="400" label="产品">
          <template slot-scope="scope">
            <div class="product-table-column" >
              <el-tag
                    style="margin:10px 10px 10px 0;"
                    v-for="(item) in scope.row.production"
                    :key="item.name"
                    :type="item.isDone?'success':'info'"
                    effect="dark">
                    {{ item.name }}
                    {{item.isDone?'(已到)':''}}
                </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="pickUpDate"
          label="通知取件日期"
          width="130"
        ></el-table-column>
        <el-table-column align="center" min-width="200" prop="comment" label="备注"></el-table-column>
        <el-table-column align="center" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <div @click="editConsumer(scope)">
              <el-button size="small" type="primary">编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,sizes, prev, pager, next"
        :current-page="pages.page * 1"
        :total="pages.total"
        :page-size="pages.pageNum * 1"
        style="margin-top: 20px"
        :page-sizes="[10, 12, 15, 17, 20, 25, 30, 100, 1000, pages.total]"
        @size-change="changePageSize"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
    <!-- 弹窗 1  -->
    <el-dialog
      :title="isEdit ? '修改信息' : '客户导入'"
      :visible.sync="dialogVisible"
      top="6vh"
      width="800px"
    >
      <el-form
        ref="form"
        :model="form"
        inline
        label-width="90px"
        label-position="left"
      >
        <el-form-item label="家长姓名">
          <el-input v-model="form.parentName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="宝宝姓名" style="margin-left:40px" >
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="套系" style="margin-left:40px">
          <el-input v-model="form.orderPrice" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="选片日期" label-width="90px" >
          <el-date-picker
            v-model="form.chooseDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="确认日期" label-width="90px" style="margin-left:20px">
          <el-date-picker
            v-model="form.confirmDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知取件日期" label-width="100px">
          <el-date-picker
            v-model="form.pickUpDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <br />
        <el-form-item label="产品" >
            <span v-for="(item,index) in form.production"
                :key="item.name">
                <el-popover
                placement="top"
                width="200"
                trigger="click">
                <el-form inline >
                    <el-form-item label="产品名称">
                        <el-input v-model="item.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="是否到店" >
                        <el-switch v-model="item.isDone" active-color="#67c23a"> </el-switch>
                    </el-form-item>
                </el-form>

                <el-tag
                    slot="reference"
                    style="margin:10px 10px 10px 0;cursor:pointer;"
                    @close="deleteProduction(index)"
                    closable
                    :type="item.isDone?'success':'info'"
                    effect="dark">
                    {{ item.name }}
                    {{item.isDone?'(已到)':''}}
                </el-tag>
                </el-popover>
            </span>
            <el-popover
                placement="top"
                width="200"
                v-model="productionVisible"
                >
                <el-form inline >
                    <el-form-item label="产品名称">
                        <el-input v-model="productObj.name" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="是否到店" >
                        <el-switch v-model="productObj.isDone" active-color="#67c23a"> </el-switch>
                    </el-form-item>
                </el-form>
                <div style="text-align: center; margin: 0">
                    <el-button type="danger" size="mini" @click="productionVisible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="addProduction">确定</el-button>
                </div>
                <el-button slot="reference" icon="el-icon-plus" @click="productObj.name=''">新增产品</el-button>
            </el-popover>
        </el-form-item>
        <br />
        <el-form-item label="备注">
          <el-input
            v-model="form.comment"
            type="textarea"
            placeholder="请输入"
            style="width: 500px;height:100px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="!isEdit"
          type="primary"
          @click="onSubmit"
          :loading="btnLoading"
          >确定新增</el-button
        >
        <el-button :loading="btnLoading" v-else type="primary" @click="onEdit"
          >确定修改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ProductionList",
  components: {},
  data() {
    return {
      //  TODO 环境切换
      formInline: {
        parentName: "",
        name: "",
        phone: "",
        keyWord: "",
        chooseDate: "",
        confirmDate: "",
        pickUpDate: ""
      },
      pages: {
        page: 1,
        pageNum: 12,
        total: 0,
      },
      tableData: [],
      dialogVisible: false,
      // 添加导入数据
      form: {
        _id: "",
        parentName: "",
        name: "",
        phone: "",
        chooseDate: "",
        confirmDate: "",
        orderPrice: "",
        comment: "",
        pickUpDate: "",
        production: []
      },
      isEdit: false, // 判断是否为编辑
      tableLoading: false,
      btnLoading: false,
      productObj: {
        name: '',
        isDone: false
      },
      productionVisible: false, // 新增产品 pop
      editProductionVisible: false, //编辑产品 pop
    };
  },
  created() {},
  mounted() {
    this.formInline.keyWord = this.$route.query.keyword?this.$route.query.keyword:'';
    this.formInline.name = this.$route.query.name?this.$route.query.name:'';
    this.getData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    clearPickDate() {
      if(this.formInline.pickUpDate===null) {
        this.formInline.pickUpDate = '';
      }
      this.search();
    },
    search() {
      this.pages.page = 1;
      this.getData();
    },
    changePage(val) {
      this.pages.page = val * 1;
      this.getData();
    },
    // 获取数据
    async getData() {
      if(this.formInline.pickUpDate===null) {
        this.formInline.pickUpDate = '';
      }
      if(this.formInline.confirmDate===null) {
        this.formInline.confirmDate = '';
      }
      if(this.formInline.chooseDate===null) {
        this.formInline.chooseDate = '';
      }
      this.tableLoading = true;
      let paramStr =
        "page=" +
        this.pages.page +
        "&pageNum=" +
        this.pages.pageNum +
        "&parentName=" +
        this.formInline.parentName +
        "&pickUpDate=" +
        this.formInline.pickUpDate +
        "&chooseDate=" +
        this.formInline.chooseDate +
        "&confirmDate=" +
        this.formInline.confirmDate +
        "&name=" +
        this.formInline.name +
        "&phone=" +
        this.formInline.phone +
        "&keyWord=" +
        this.formInline.keyWord;
      await axios
        .get(this.$baseUrl + "/production?" + paramStr)
        .then((res) => {
          setTimeout(() => {
            this.tableLoading = false;
          }, 200);
          this.tableData = res.data.list;
          this.pages = res.data.pages;
        })
        .catch((err) => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    //导出excel
    exportExcel() {
      if(this.formInline.pickUpDate===null) {
        this.formInline.pickUpDate = '';
      }
      axios
        .get(
          this.$baseUrl +
            "/production/exportExcel?" +
            "page=" +
            this.pages.page +
            "&pageNum=" +
            this.pages.pageNum +
            "&parentName=" +
            this.formInline.parentName +
            "&pickUpDate=" +
            this.formInline.pickUpDate +
            "&name=" +
            this.formInline.name +
            "&phone=" +
            this.formInline.phone +
            "&keyWord=" +
            this.formInline.keyWord
            )
        .then((res) => {
          console.log("导出");
          window.open(res.data.url);
        });
    },
    // 点击导入
    openAdd() {
      this.form = {
        _id: "",
        parentName: "",
        name: "",
        phone: "",
        chooseDate: "",
        confirmDate: "",
        orderPrice: "",
        comment: "",
        pickUpDate: "",
        production: []
      };
      this.dialogVisible = true;
      this.isEdit = false;
    },
    // 点击编辑，编辑已有用户信息
    editConsumer(scope) {
      this.isEdit = true;
      this.dialogVisible = true;
      let { parentName, name, phone, chooseDate, confirmDate, orderPrice, pickUpDate, comment, production, _id } = scope.row;
      this.form = {
        parentName,
        name,
        phone,
        chooseDate,
        confirmDate,
        orderPrice,
        comment,
        pickUpDate,
        production: JSON.parse(JSON.stringify(production)),
        _id,
      };
    },
    // 确认添加
    onSubmit() {
      this.btnLoading = true;
      let param = { ...this.form };
      delete param._id;
      console.log(param, "新增");
      if (param.name.trim() == "" && param.phone.trim() == "") {
        this.$message({
          showClose: true,
          message: "请填写姓名或手机号",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      axios
        .post(this.$baseUrl + "/production/addConsumer", param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data, "添加成功");
            this.$message({
              duration: "2000",
              showClose: true,
              message: "添加成功！",
              type: "success",
            });
            this.dialogVisible = false;
            this.btnLoading = false;
            this.search();
          }
          if (res.data.code == 302) {
            this.$message({
              duration: "2000",
              showClose: true,
              message: "重复手机号请检查输入信息！",
              type: "warning",
            });
            this.btnLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    // 确认编辑
    onEdit() {
      this.btnLoading = true;
      let param = { ...this.form };
      console.log(param, "编辑");
      if (param.name.trim() == "" && param.phone.trim() == "") {
        this.$message({
          showClose: true,
          message: "请填写姓名或手机号",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      axios
        .post(this.$baseUrl + "/production/editInfo", param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data, "修改数据");
            this.$message({
              duration: "2000",
              showClose: true,
              message: "修改成功！",
              type: "success",
            });
            this.btnLoading = false;
            this.search();
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    // 添加产品
    addProduction() {
      console.log(this.productObj)
      this.form.production.push({...this.productObj});
      this.productionVisible = false;
      this.$forceUpdate();
    },
    // 删除产品
    deleteProduction(index) {
      this.form.production.splice(index,1);
      this.$forceUpdate();
    },
    // 修改每页选择数量
    changePageSize(val) {
      this.pages.pageNum = val;
      this.getData();
    },
  },
};
</script>
<style scoped lang="less">
.kmCenter {
  height: 100%;
  width: 100%;
  padding: 10px 50px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  .searchBar,
  .manlist {
    margin: 10px auto;
  }
  .searchBar {
    border: 1px solid #284faa;
    border-radius: 4px;
    padding-top: 22px;
  }
  .mainList {
    padding: 10px 0;
  }
  .redFont {
    font-weight: 600;
    color: #492525;
  }
  .blueFont {
    font-weight: 600;
    color: #2e62cd;
  }
  .greenFont {
    font-weight: 600;
    color: #349e69;
  }
  .product-table-column {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
</style>